import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { I18nextProvider } from 'react-i18next';
import i18n from '@/i18n';
import updateLocale from 'dayjs/plugin/updateLocale';
// import utc from 'dayjs/plugin/utc';
// import timezone from 'dayjs/plugin/timezone';

import { HashRouter } from 'react-router-dom';
import Main from '@/Main';
import Theme from '@/Theme';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/pl';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';
import { msalInstance } from '@/msalConfig';
import { MsalProvider } from '@azure/msal-react';

const DrawerSearchWidget = lazy(() => import('@/widget/DrawerSearchWidget'));
const HuBOL = lazy(() => import('@/HuBOL'));


dayjs.Ls.it.weekStart = 1;
dayjs.Ls.en.weekStart = 1;
dayjs.Ls.de.weekStart = 1;
dayjs.Ls.pl.weekStart = 1;
dayjs.Ls.fr.weekStart = 1;
dayjs.Ls.nl.weekStart = 1;

dayjs.extend(updateLocale);
// dayjs.extend(utc);
// dayjs.extend(timezone);
// dayjs.tz.setDefault('Europe/Rome');

declare global {
  interface Window {
    isBookingOnlinePage: boolean;
  }
}

LicenseInfo.setLicenseKey(
  '52584ccceb73b1c914aa1faf93445415Tz0xMDMzMjYsRT0xNzY0NzUzNTQ1MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
);

function App() {

  console.log('render App');

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    msalInstance.initialize().then(() => setIsInitialized(true));
  }, []);

  if (!isInitialized) return <div>MSAL...</div>;

  return (
      <MsalProvider instance={msalInstance}>
        <I18nextProvider i18n={i18n}>
          <HashRouter>
            <Main>
                <Theme>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}>
                    {
                      window.isBookingOnlinePage ? (
                        <Suspense fallback={<Backdrop open={true} style={{ zIndex: 99 }}>
                          <CircularProgress color="inherit" />
                        </Backdrop>}>
                          <HuBOL />
                        </Suspense>
                      ) : (
                        <Suspense fallback={<Backdrop open={true} style={{ zIndex: 99 }}>
                          <CircularProgress color="inherit" />
                        </Backdrop>}>
                          <DrawerSearchWidget />
                        </Suspense>
                      )}
                  </LocalizationProvider>
                </Theme>
            </Main>
          </HashRouter>
        </I18nextProvider>
      </MsalProvider>
  );
}

export default App;
