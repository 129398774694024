import { PublicClientApplication, Configuration, LogLevel, PopupRequest } from '@azure/msal-browser';

const msalConfig: Configuration = {
  auth: {
    clientId: "93e24f68-236b-4fc2-a6b7-5b436059ff6a", // Твой Client ID
    authority: "https://myhutest.huopenair.com/myhutest.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN", // Policy в Azure B2C
    knownAuthorities: ["myhutest.huopenair.com"], // Домен B2C
    redirectUri: `${window.location.origin}/`, // URL для редиректа после логина
    // postLogoutRedirectUri: `${window.location.origin}/logout`, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true
    // navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.

  },
  cache: {
    cacheLocation: "localStorage", // Или "localStorage"
    storeAuthStateInCookie: false, // Для поддержки старых браузеров
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Trace,
      piiLoggingEnabled: false
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);


export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};
